import React from "react";
import { Box, Heading } from "grommet";
const Page404 = () => {
  return (
    <Box>
      <Heading>Oops... Page not Found</Heading>
    </Box>
  );
};

export default Page404;
